<template>
  <section class="section is-page-header">
    <div class="container">
      <div class="page-header">
        <PageHeaderLogo class="page-header-logo" />
        <transition name="menu">
          <div
            v-show="!isMobile || menuOpen"
            class="page-header-menu"
            :class="{ 'has-submenu-open': !!activeSubmenu }"
          >
            <page-header-meta @close-menu="activeSubmenu = ''" />
            <MenuMain
              v-if="mainMenu.length > 0"
              v-model="activeSubmenu"
              :menu="mainMenu"
            />
          </div>
        </transition>
      </div>
    </div>
    <client-only>
      <div
        v-if="burgerVisible"
        class="page-header-burger"
        :class="{ 'is-active': menuOpen }"
        @click="menuOpen = !menuOpen"
      >
        <button class="button is-primary">
          <SpriteSymbol name="burger" />
        </button>
      </div>
    </client-only>
    <transition name="fade">
      <div
        v-if="(isMobile && menuOpen) || (!isMobile && activeSubmenu)"
        class="page-header-overlay"
        @click="overlayClick"
      />
    </transition>
  </section>
</template>

<script setup lang="ts">
const route = useRoute()

const menuOpen = ref(false)
const activeSubmenu = ref('')

const initData = await useInitData()
const mainMenu = initData.value.mainMenuLinks

// Close menu on route change
watch(
  () => route.fullPath,
  () => {
    menuOpen.value = false
    activeSubmenu.value = ''
  },
)

const { isMobile } = usePortalViewport()

const burgerVisible = computed(() => {
  const name = (route.name || '') as string
  return !name.includes('wizard') && isMobile.value
})

const overlayClick = () => {
  menuOpen.value = false
  activeSubmenu.value = ''
}
</script>

<style lang="scss">
.section.is-page-header {
  padding-top: 0;
  padding-bottom: 0;
  > .container {
    z-index: 100;
    @include mobile {
      position: static;
    }
  }
}
.page-header {
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: $size-6 0;
  justify-content: center;
  border-bottom: 1px solid rgba($black, 0.1);
  @include tablet {
    justify-content: space-between;
    padding: $size-4 0;
    z-index: 200;
  }
}

.page-header-logo {
  display: block;
  img {
    display: block;
  }
}

.page-header-menu {
  @include mobile {
    position: fixed;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 10rem;
    background: white;
    z-index: 100;
    padding: $size-5;
    border-radius: 1.75rem;
    transition: 0.3s;
    overflow: auto;
    height: calc(100vh - 10rem - 5rem);
  }
}

.page-header-burger {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  svg {
    transition: 0.3s;
    rect {
      transition: 0.3s;
      transform-origin: center center;
    }
    #burger-line-1 {
      transform-origin: left;
    }
    #burger-line-4 {
      transform-origin: right;
    }
  }
  &.is-active {
    svg {
      /* transform: rotate(90deg); */
    }
    #burger-line-1 {
      opacity: 0;
      transform: scaleX(0);
    }
    #burger-line-2 {
      transform: rotate(-45deg);
    }
    #burger-line-3 {
      transform: rotate(45deg);
    }
    #burger-line-4 {
      opacity: 0;
      transform: scaleX(0);
    }
  }

  button {
    padding: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.page-header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(black, 0.8);
  @include tablet {
    background: rgba($white-ter, 0.6);
  }
  &.fade-enter-active,
  &.fade-leave-active {
    transition: 0.3s;
  }
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
}

@include mobile {
  .menu-enter-active,
  .menu-leave-active {
    transition: 0.3s;
  }
  .menu-enter,
  .menu-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.submenu-enter-active,
.submenu-leave-active {
  transition: 0.3s;
  transform-origin: top;
}
.submenu-enter,
.submenu-leave-to {
  opacity: 0;
  @include tablet {
    transform: scaleY(0.9);
  }
}
</style>
