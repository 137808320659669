import type { OrugaOptions } from '@oruga-ui/oruga-next'
import { ConfigProgrammatic, Modal, Loading } from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

export default defineNuxtPlugin((nuxtApp) => {
  const config: OrugaOptions = {
    ...bulmaConfig,
  }

  ConfigProgrammatic.setOptions(config)
  nuxtApp.vueApp.use(Modal)
  nuxtApp.vueApp.use(Loading)
})
