<template>
  <div v-if="footerMenu.length > 0" class="footer-navigation-right">
    <VuepalLink
      v-for="(link, index) in footerMenu"
      :key="index"
      :to="link.link.url?.path"
      class="is-block"
      >{{ link.link.label }}</VuepalLink
    >
  </div>
</template>

<script setup lang="ts">
const initData = await useInitData()

const footerMenu = initData.value.footerMenuLinks
</script>

<style lang="scss">
.footer-navigation-right {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @include mobile {
    a:nth-child(even) {
      text-align: right;
    }
  }
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }

  a {
    font-weight: $weight-extrabold;
    color: $black;
    font-size: $size-6;
  }
}
</style>
