export interface DrupalUser {
  fetched: boolean
  accessToolbar: boolean
  loggedIn: boolean
  isPremium: boolean
  hasImage: boolean
  uid: number
  mail: string
  name: string
}

export default function () {
  return useState<DrupalUser>('drupalUser', () => ({
    fetched: false,
    accessToolbar: false,
    loggedIn: false,
    isPremium: false,
    hasImage: false,
    uid: 0,
    mail: '',
    name: '',
  }))
}
