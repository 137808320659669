<template>
  <div class="section is-breadcrumb">
    <Container>
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ol
          ref="list"
          itemscope
          itemtype="http://schema.org/BreadcrumbList"
          class="flex whitespace-nowrap items-center"
        >
          <li
            v-for="(link, index) in linksComputed"
            :key="index"
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
          >
            <component :is="link.tag" v-bind="link.props" itemprop="item">
              <span itemprop="name">
                {{ link.title }}
              </span>
            </component>
            <meta itemprop="position" :content="`${index + 2}`" />
          </li>
        </ol>
      </nav>
    </Container>
  </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbFragment } from '#graphql-operations'
import type { PageLanguage } from '#language-negotiation/language'
import { NuxtLink } from '#components'

const props = defineProps<{
  links: BreadcrumbFragment[]
  language?: PageLanguage
}>()

const list = ref<HTMLOListElement | null>(null)

const linksComputed = computed(() => {
  return props.links.map((link) => {
    const { title, url } = link
    const to = url?.path
    return {
      tag: to ? NuxtLink : 'span',
      props: to ? { to } : {},
      title,
    }
  })
})

watch(linksComputed, () => {
  nextTick(() => {
    if (list.value && window.innerWidth < 768) {
      list.value.querySelector('li:last-child')?.scrollIntoView()
    }
  })
})
</script>

<style lang="scss">
.section.is-breadcrumb {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: $weight-bold;
  position: relative;
  z-index: 10;
  .container {
    position: relative;
  }
  .breadcrumb {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    padding-top: $size-6;
  }
  @include mobile {
    padding: 0;
    .breadcrumb {
      flex-wrap: nowrap;
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      li {
        padding-bottom: 1rem;
      }
      li:first-child {
        padding-left: 1rem;
      }
      li:last-child {
        padding-right: 1rem;
      }
    }
  }

  + .page {
    .section:first-child:not(.subsequent) {
      padding-top: 4rem;
      @include desktop {
        padding-top: 6rem;
      }
    }
  }
}
</style>
