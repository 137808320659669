<template>
  <div class="page-header-meta">
    <ul class="meta">
      <li>
        <PageHeaderLanguageSwitcher />
      </li>
      <li>
        <NuxtLink :to="{ name: 'search' }" class="meta-link">
          <div class="icon">
            <SpriteSymbol name="search" />
          </div>
          <span>{{ $texts('search', 'Suche') }}</span>
        </NuxtLink>
      </li>
      <li>
        <a href="/user" class="meta-link">
          <div class="icon">
            <SpriteSymbol name="account" />
          </div>
          <span>{{ $texts('myAccount', 'Mein Konto') }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const { $texts } = useNuxtApp()
</script>

<style lang="scss">
.page-header-meta {
  @include mobile {
    border-bottom: 1px solid $border;
    margin-bottom: 1rem;
  }
  @include tablet {
    margin-bottom: 1rem;
  }
}

.meta {
  display: flex;
  justify-content: flex-end;
  @include mobile {
    justify-content: space-between;
  }
}

.meta-link {
  color: $text-light;
  font-weight: $weight-bold;
  font-size: $size-7;
  display: flex;
  align-items: center;
  appearance: none;
  border: 0;
  background: none;
  line-height: 1;
  cursor: pointer;

  @include tablet {
    padding: 0.5rem;
    margin-left: 1rem;
  }
  &:hover {
    color: $text;
  }
  .icon {
    //font-size: 14px;
    //width: 1em;
    //height: 1em;
    //margin-right: 0.25rem;
    //margin-top: -0.1em;
    svg {
      //height: 100%;
      //width: 100%;
      font-size: 14px;
      width: 1em;
      height: 1em;
      fill: currentColor;
    }
  }
}
</style>
