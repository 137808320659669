<template>
  <div class="bk-main-canvas relative font-[&quot;Open Sans&quot;]">
    <ClientOnly>
      <div
        v-if="drupalUser.accessToolbar && !isEditing"
        class="is-hidden-touch"
      >
        <VuepalAdminToolbar :key="language" />
        <div class="local-tasks-wrapper">
          <div class="container">
            <VuepalLocalTasks />
          </div>
        </div>
      </div>
    </ClientOnly>
    <NuxtLoadingIndicator color="#f3aa4f" />
    <PageHeader />

    <NuxtPageDependency>
      <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
    </NuxtPageDependency>

    <div>
      <NuxtPage />
    </div>
  </div>
  <Footer v-if="!isEditing" />
  <client-only v-if="!isMobile">
    <page-background />
  </client-only>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const breadcrumb = useBreadcrumbLinks()
const siteInfo = await useSiteInfo()

const prefix = siteInfo.siteName === 'familleSuisse' ? '' : '/emp'

useHead({
  title: siteInfo.siteName,
  htmlAttrs: {
    class: 'is-' + siteInfo.siteName,
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    {
      key: 'msapplication-TileColor',
      name: 'msapplication-TileColor',
      content: '#da532c',
    },
    {
      key: 'theme-color',
      name: 'theme-color',
      content: '#ffffff',
    },
  ],
  link: [
    {
      key: 'icon',
      rel: 'icon',
      type: 'image/x-icon',
      href: prefix + '/favicon.ico',
    },
    {
      key: 'apple-touch-icon',
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: prefix + '/apple-touch-icon.png',
    },
    {
      key: 'icon2',
      rel: 'icon',
      type: 'image/png',
      href: prefix + '/favicon-32x32.png',
      sizes: '32x32',
    },
    {
      key: 'icon3',
      rel: 'icon',
      type: 'image/png',
      href: prefix + '/favicon-16x16.png',
      sizes: '16x16',
    },
    { rel: 'manifest', href: '/site.webmanifest' },
    {
      key: 'manifest',
      rel: 'mask-icon',
      href: prefix + '/safari-pinned-tab.svg1',
      color: '#5bbad5',
    },
    {
      key: 'shortcut-icon',
      rel: 'shortcut,icon',
      href: prefix + '/favicon.ico',
    },
  ],
})

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const showBreadcrumb = computed(() => route.meta.hideBreadcrumb !== true)

const { isMobile } = usePortalViewport()

useHead({
  htmlAttrs: {
    lang: language.value,
  },
})
</script>
