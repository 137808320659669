<template>
  <div>
    <div v-click-away="clickAway" class="dropdown is-active">
      <div class="dropdown-trigger">
        <button class="meta-link" @click="openLanguageSwitcher">
          <span class="icon">
            <SpriteSymbol name="web" />
          </span>
          <span class="upper">{{ currentLanguage }}</span>
        </button>
      </div>
      <div
        v-if="langSwitchDropdownOpen"
        id="dropdown-menu"
        class="dropdown-menu"
        role="menu"
      >
        <div class="dropdown-content">
          <component
            v-bind="link.attrs"
            is="a"
            v-for="link in mappedLanguageLinks"
            :key="link.code"
            class="dropdown-item"
            :title="link.label"
          >
            {{ link.label }}
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'

type LanguageLink = {
  code: string
  active: boolean
  to: string
}

const languages: ComputedRef<LanguageLink[]> = useLanguageLinks()
const currentLanguage = useCurrentLanguage()
const languageLinks = computed(() => {
  return languages.value.filter((l) => l.code !== currentLanguage.value)
})
const ALL = ['de', 'fr', 'it']
const router = useRouter()
const route = useRoute()

function getLanguageLabel(code: string) {
  if (code === 'de') {
    return 'Deutsch'
  } else if (code === 'fr') {
    return 'Francais'
  } else if (code === 'it') {
    return 'Italiano'
  }

  return code
}

const mappedLanguageLinks = computed(() => {
  return ALL.map((code) => {
    const link = languageLinks.value.find((v: any) => v.code === code)
    // First resolve the provided language link.
    const resolved = link ? router.resolve(link.to).fullPath : undefined

    // Use the resolved link and again resolve it, this time using the
    // current route query params.
    const href = resolved
      ? router.resolve({ path: resolved, query: route.query }).fullPath
      : undefined

    return {
      active: !!link?.active,
      code,
      label: getLanguageLabel(code),
      isLink: !!link,
      attrs: {
        href: href || undefined,
      },
    }
  }).filter((l) => l.code !== currentLanguage.value)
})

const openLanguageSwitcher = () => {
  langSwitchDropdownOpen.value = !langSwitchDropdownOpen.value
}

const langSwitchDropdownOpen = ref(false)
const clickAway = () => {
  if (langSwitchDropdownOpen.value) {
    langSwitchDropdownOpen.value = false
  }
}
</script>

<style lang="scss">
.upper {
  text-transform: uppercase;
}
</style>
