<template>
  <nuxt-link to="/">
    <img
      v-if="siteInfo.siteName === 'empiricus'"
      class="emp"
      alt="empiricus"
      src="~/assets/images/empiricus-logo.png"
    />
    <SpriteSymbol v-else class="fs" alt="famillesuisse" name="logo" />
  </nuxt-link>
</template>

<script setup lang="ts">
const siteInfo = await useSiteInfo()
</script>

<style lang="scss" scoped>
.fs {
  height: 48px;
  @include tablet {
    height: 77px;
    max-width: 100px;
  }
}

.emp {
  height: 35px;
  @include tablet {
    height: 40px;
  }
}
</style>
