import { useWindowSize } from '@vueuse/core'

export function usePortalViewport() {
  const size = useWindowSize()
  const isMobile = computed(() => {
    return size.width.value < 768
  })

  return { isMobile }
}
