<template>
  <div class="buttons">
    <VuepalLink to="/user/register" class="button is-link is-fullwidth">
      {{ $texts('register_now', 'Jetzt registrieren') }}
    </VuepalLink>

    <VuepalLink
      to="/user/login"
      class="button is-link is-inverted is-fullwidth"
    >
      {{ $texts('login_for_members', 'Anmelden für Mitglieder') }}
    </VuepalLink>
  </div>
</template>

<script setup lang="ts">
const { $texts } = useNuxtApp()
</script>

<style lang="scss" scoped>
.buttons {
  @include desktop {
    margin-left: 65px;
  }
}
</style>
