<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <ul class="navbar-menu">
      <li
        v-for="(item, index) in menu"
        :key="index"
        :class="{ 'is-active': modelValue === item.link.label }"
      >
        <a
          :href="item.link?.url?.path"
          class="navbar-item"
          @mouseenter="onMouseEnter(item)"
          @mouseleave="onMouseLeave"
          @click.prevent="onClick(item, $event)"
        >
          <span>{{ item.link.label }}</span>
          <SpriteSymbol v-if="item.subtree.length" name="caret-down" />
        </a>
        <transition name="submenu">
          <div
            v-if="item.subtree.length"
            v-show="modelValue === item.link.label"
            class="navbar-submenu"
          >
            <div class="navbar-submenu-header">
              <NuxtLink
                class="is-size-4-desktop has-text-weight-bold"
                :to="item.link?.url?.path"
              >
                <span class="is-hidden-mobile">{{ item.link.label }}</span>
                <span class="is-hidden-tablet">{{
                  $texts('menuOverview', 'Übersicht')
                }}</span>
              </NuxtLink>
            </div>
            <ul>
              <li
                v-for="(subitem, subIndex) in item.subtree"
                :key="index + '_' + subIndex"
              >
                <VuepalLink
                  :to="subitem.link?.url?.path"
                  class="navbar-item link"
                >
                  {{ subitem.link?.label }}
                </VuepalLink>
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { MenuLinkTreeElementFirstFragment } from '#graphql-operations'

const props = defineProps<{
  menu: MenuLinkTreeElementFirstFragment[]
  modelValue?: string
}>()

const emit = defineEmits<(e: 'update:modelValue', value: Element) => void>()

const router = useRouter()
const { $texts } = useNuxtApp()

let timeout: any = null

const { isMobile } = usePortalViewport()

const onMouseEnter = (item: any) => {
  if (!isMobile.value) {
    clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      const newValue = item.subtree.length ? item.link.label : ''
      emit('update:modelValue', newValue)
    }, 300)
  }
}

const onMouseLeave = () => {
  clearTimeout(timeout)
}

const onClick = (item: any, event: MouseEvent) => {
  if (!item.subtree.length) {
    if (isAbsolute(item.link?.url?.path)) {
      window.open(item.link?.url?.path, '_blank')
    } else {
      router.push(item.link?.url?.path)
    }
    return
  }

  event.preventDefault()
  if (isMobile.value) {
    if (props.modelValue === item.link.label) {
      emit('update:modelValue', '' as unknown as Element)
    } else {
      emit('update:modelValue', item.link.label)
    }
  } else {
    emit('update:modelValue', item.link.label)
  }
}

const isAbsolute = (href: string) => {
  return href.indexOf('http://') === 0 || href.indexOf('https://') === 0
}
</script>

<style lang="scss">
@use '~/assets/scss/utilities/functions.scss' as *;
.navbar-menu {
  @include mobile {
    > li {
      border-bottom: 1px solid $white-bis;
    }
  }
  @include tablet {
    display: flex;
    margin-bottom: -5px;
  }
  > li {
    &.is-active {
      > a {
        color: $primary;

        @include tablet {
          svg {
            transform: rotate(180deg);
          }
          &:after {
            content: '';
            display: block;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 10px 8px;
            border-color: transparent transparent $primary transparent;
            position: absolute;
            bottom: -1.25rem;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    &:last-child > a {
      padding-right: 0;
    }
    > a {
      position: relative;
      font-weight: $weight-bold;
      font-size: $size-5;
      text-transform: uppercase;
      display: flex;
      padding: 1em 0;
      line-height: 1;
      align-items: center;
      justify-content: space-between;
      svg {
        fill: currentColor;
        height: 0.8em;
        width: 0.8em;
        margin-left: 0.25em;
      }
      @include tablet {
        font-size: $size-7;
        padding: 0.5em 1em;
        justify-content: flex-start;
      }
      @include desktop {
        font-size: $size-6;
      }
      @include widescreen {
        font-size: rem(19);
      }
    }
  }
}

.navbar-submenu {
  @include tablet {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 100;
    background: white;
    padding: $size-4;
    overflow: auto;
    top: 100%;
    height: auto;
    box-shadow: 0 4px 20px 0 rgba($black, 0.1);
    border-radius: 0 0 $radius-large;
    border-top: 3px solid $primary;
    ul {
      columns: 3;
    }
  }
  ul {
    a {
      display: block;
      padding: 0.25em 0;
    }
  }
}

.navbar-submenu-header {
  @include tablet {
    margin-bottom: $size-4;
  }
}
</style>
