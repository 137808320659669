
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'account': { import: () => import('/home/portal/www/frontend/assets/symbols/account.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","viewBox":"0 0 24 24","id":"account"} },
  'add-hexagon': { import: () => import('/home/portal/www/frontend/assets/symbols/add-hexagon.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 25","id":"add-hexagon"} },
  'arrow-left': { import: () => import('/home/portal/www/frontend/assets/symbols/arrow-left.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-left"} },
  'arrow-right': { import: () => import('/home/portal/www/frontend/assets/symbols/arrow-right.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-right"} },
  'burger': { import: () => import('/home/portal/www/frontend/assets/symbols/burger.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg","id":"burger"} },
  'caret-down': { import: () => import('/home/portal/www/frontend/assets/symbols/caret-down.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 18 11","xmlns":"http://www.w3.org/2000/svg","id":"caret-down"} },
  'chevron-down': { import: () => import('/home/portal/www/frontend/assets/symbols/chevron-down.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","width":"24","height":"24","viewBox":"0 0 24 24","id":"chevron-down"} },
  'chevron-right': { import: () => import('/home/portal/www/frontend/assets/symbols/chevron-right.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512","id":"chevron-right"} },
  'circle-notch': { import: () => import('/home/portal/www/frontend/assets/symbols/circle-notch.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","id":"circle-notch"} },
  'logo': { import: () => import('/home/portal/www/frontend/assets/symbols/logo.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 150 107","id":"logo"} },
  'magnifyingglass': { import: () => import('/home/portal/www/frontend/assets/symbols/magnifyingglass.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"magnifyingglass"} },
  'newspaper': { import: () => import('/home/portal/www/frontend/assets/symbols/newspaper.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"newspaper"} },
  'paperplane': { import: () => import('/home/portal/www/frontend/assets/symbols/paperplane.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"paperplane"} },
  'play': { import: () => import('/home/portal/www/frontend/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"viewBox":"44.9 44.9 83.2 83.2","fill":"none","xmlns":"http://www.w3.org/2000/svg","style":"filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));","id":"play"} },
  'search': { import: () => import('/home/portal/www/frontend/assets/symbols/search.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","viewBox":"0 0 24 24","id":"search"} },
  'web': { import: () => import('/home/portal/www/frontend/assets/symbols/web.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","viewBox":"0 0 24 24","id":"web"} },
}
  