precision mediump float;

uniform vec2 u_resolution;
uniform float u_time;
uniform float u_flow;
uniform float u_scroll;
uniform float u_height_content;
uniform float u_height_window;
uniform float u_circles_x[7];
uniform float u_circles_y[7];
uniform float u_circles_r[7];
uniform float u_circles_a[7];

/**
 * Convert r, g, b to normalized vec3
 */
vec3 rgb(float r, float g, float b) {
  return vec3(r / 255.0, g / 255.0, b / 255.0);
}

// Colors used for drawing the gradient.
const vec4 COLOR_PURPLE = vec4(1., 1., 1., 1.);
const vec4 COLOR_GREEN = vec4(1., 1., 1., 1.);
const vec3 COLOR_YELLOW = vec3(243. / 255., 170. / 255., 79. / 255.);

// Settings this to true will show the grid with its subspaces used for drawing
// the particles.
const bool DEBUG = true;

// The viscosity of the liquid of the particles. A lower value means it will
// behave more like water.
const float VISCOSITY = 3.5;

// Just a random magic number used the feed the pseudo random noise functions.
const float MAGIC = 21.1238209348;

/**
 * Calculate a random float noise value given a position (0 to 1).
 */
float noise1(vec2 p) {
  p = fract(p * vec2(MAGIC, MAGIC * 3.7));
  p += dot(p, p + MAGIC / 7.2);
  return fract(p.x * p.y);
}

/**
 * Given a vec2, generate a vec2 of random noise values, using the computed
 * noise of the argument as the input for the returned y noise.
 */
vec2 noise2(vec2 p) {
  float n = noise1(p);
  return vec2(n, noise1(p + n));
}

/**
 * Get a random position inside the given grid cell (0 - 1) using a noise
 * function.
 */
vec2 get_particle_position(vec2 id, float a) {
  vec2 noise = noise2(id);

  float x = sin(((u_time + 90.2) / VISCOSITY) * noise.x);
  float y = cos(((u_time + 30.7) / VISCOSITY) * noise.y);

  vec2 pos = vec2(x, y) * 50.;
  pos.y = pos.y + id.y + (u_flow * a) * 300.;
  pos.x += id.x;
  return pos;
}

/**
 * Draw a circle at vec2 `pos` with radius `rad` and
 * color `color`.
 */
vec4 circle(vec2 uv, vec2 pos, float rad, vec3 color) {
  float d = length(pos - uv) - rad;
  float t = 1. - smoothstep(0.0, 5., abs(rad - d));
  vec4 circle = vec4(color, t);
  return circle;
}

/**
 * Main draw call.
 */
void main() {
  // Calculate the fragment position and transform it to a square space.
  vec2 st = gl_FragCoord.xy / u_resolution.xy;
  st.x *= u_resolution.x / u_resolution.y;

  float x = u_resolution.x / 100.;
  float y = u_flow * 100.;

  // Background layer (white).
  vec4 layer1 = vec4(rgb(255.0, 255.0, 255.0), 0.0);

  // Circles.
  for (int i = 0; i < 7; ++i) {
    float x = u_circles_x[i];
    float y = u_circles_y[i];
    float r = u_circles_r[i];
    float a = u_circles_a[i];

    vec2 center = get_particle_position(vec2(x, y), a);
    vec4 circle_f = circle(gl_FragCoord.xy, center, r, COLOR_YELLOW);
    layer1 = mix(layer1, circle_f, circle_f.a * a);
  }

  gl_FragColor = layer1;
}
