<template>
  <footer class="section has-background-white-bis is-footer">
    <div class="container">
      <div class="columns">
        <div class="column is-2 is-3-desktop has-text-centered-mobile">
          <PageHeaderLogo />
        </div>
        <div class="column is-6">
          <MenuFooter />
        </div>
        <div class="column has-text-centered-mobile">
          <footer-login />
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer-logo {
  max-width: 100px;
}

.section.is-footer {
  position: relative;
  /* Reserve some space for the burger. */
  @include mobile {
    padding-bottom: 5rem;
  }
}
</style>
