<template>
  <div class="page-background">
    <transition name="fade">
      <div v-if="!webglSupported" class="background-fallback" />
    </transition>

    <div v-show="webglSupported" class="background-webgl">
      <canvas ref="canvas" :style="canvasStyle" class="background-canvas" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BackgroundAnimation from './bg'
// @ts-ignore
import fragmentShader from '~/components/PageBackground/shader/fs_gradient.glsl'

const animation = ref<BackgroundAnimation | null>(null)
const webglSupported = ref(false)
const viewportWidth = ref(1024)
const viewportHeight = ref(768)
const canvas = ref(null)

const canvasStyle = computed(() => ({
  width: viewportWidth.value + 'px',
  height: viewportHeight.value + 'px',
}))

onMounted(() => {
  webglSupported.value = !!BackgroundAnimation.webglSupported()

  viewportWidth.value = window.innerWidth
  viewportHeight.value = window.innerHeight

  if (webglSupported.value) {
    animation.value = new BackgroundAnimation(
      canvas.value,
      viewportWidth.value,
      viewportHeight.value,
    )
    animation.value.init(fragmentShader)

    animation.value.start()
  }
})

watchEffect(() => {
  // console.log('UPDATE')
})

onBeforeUnmount(() => {
  // console.log('DESTROY')
  if (animation.value) {
    animation.value.stop()
  }
})
</script>

<style lang="scss">
.page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
