/* eslint-disable */
import * as twgl from 'twgl.js'

import Lerp from './LERP'

import vsQuad from './shader/vs_quad.glsl'

const DEBUG = true

class BackgroundAnimation {
  constructor(canvas, width, height) {
    this.canvas = null
    this.gl = null

    this.progGradient = null
    this.bufferInfo = null
    this.arrays = {
      position: [-1, -1, 0, 1, -1, 0, -1, 1, 0, -1, 1, 0, 1, -1, 0, 1, 1, 0],
    }

    this.rafToken = null

    this.contentHeight = new Lerp(0.05, document.documentElement.offsetHeight)
    this.scrollY = new Lerp(0.1, 0)
    this.flow = new Lerp(0.13, 10)

    this.lastScrollY = window.scrollY

    this.viewport = {
      width,
      height,
    }

    this.setCanvas(canvas)
  }

  init(fsGradient) {
    try {
      this.progGradient = twgl.createProgramInfo(this.gl, [vsQuad, fsGradient])
      this.bufferInfo = twgl.createBufferInfoFromArrays(this.gl, this.arrays)
    } catch (e) {
      console.log(e)
    }
  }

  setViewport({ width, height }) {
    this.viewport.width = width
    this.viewport.height = height
    this.canvas.width = width
    this.canvas.height = height
  }

  setCanvas(canvas) {
    this.canvas = canvas

    this.setViewport(this.viewport)
    this.gl = canvas.getContext('webgl', { premultipliedAlpha: false })
  }

  start() {
    this.render()
  }

  stop() {
    window.cancelAnimationFrame(this.rafToken)
  }

  getUniforms(time) {
    const contentHeight = this.contentHeight.getValue()
    const windowHeight = this.viewport.height

    // Array of [x, y, radius, alpha]
    const circles = [
      [80, 200, 100, 0.8],
      [0, -1500, 80, 0.9],
      [100, -1690, 30, 0.2],
      [10, 2000, 10, 0.5],
      [this.viewport.width - 100, -800, 50, 0.5],
      [30, -this.viewport.height + 500, 50, 0.4],
      [this.viewport.width - 100, -400, 90, 0.2],
    ]

    return {
      u_time: time / 1000,
      u_flow: this.flow.getValue(),
      u_scroll: this.scrollY.getValue(),
      u_height_content: contentHeight,
      u_height_window: windowHeight,
      u_resolution: [this.viewport.width, this.viewport.height],
      u_circles_x: circles.map((v) => v[0]),
      u_circles_y: circles.map((v) => v[1]),
      u_circles_r: circles.map((v) => v[2]),
      u_circles_a: circles.map((v) => v[3]),
    }
  }

  render(time) {
    twgl.resizeCanvasToDisplaySize(this.canvas)
    this.gl.viewport(0, 0, this.viewport.width, this.viewport.height)

    this.contentHeight.setTarget(document.documentElement.offsetHeight)
    this.contentHeight.step()

    this.scrollY.setTarget(window.scrollY)
    this.scrollY.step()

    let delta = (window.scrollY - this.lastScrollY) / 900
    delta = Math.min(Math.max(delta, -1), 1)

    this.flow.move(delta)
    this.flow.step()
    this.lastScrollY = window.scrollY

    const uniforms = this.getUniforms(time)

    if (DEBUG) {
      window._bg_uniforms = uniforms
    }

    this.gl.useProgram(this.progGradient.program)
    twgl.setBuffersAndAttributes(this.gl, this.progGradient, this.bufferInfo)
    twgl.setUniforms(this.progGradient, uniforms)
    twgl.drawBufferInfo(this.gl, this.bufferInfo)

    this.rafToken = window.requestAnimationFrame(this.render.bind(this))
  }
}

/**
 * Checks if the browser supports WebGL.
 */
function webglSupported() {
  try {
    if (window.hasOwnProperty('WebGLRenderingContext')) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('webgl')

      if (context) {
        return true
      }
    }
  } catch (e) {
    return false
  }
}

BackgroundAnimation.webglSupported = webglSupported

if (module.hot) {
  module.hot.accept()
}

export default BackgroundAnimation
