import { defineNuxtPlugin } from '#app'
import { changeLocale } from '@formkit/vue'

export default defineNuxtPlugin({
  name: 'formkitLanguage',
  setup() {
    const currentLanguage = useCurrentLanguage()
    changeLocale(currentLanguage.value)
  },
})
