import { default as indexCanAjZThJGMeta } from "/home/portal/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as index6qpNV45gGBMeta } from "/home/portal/www/frontend/pages/article/index.vue?macro=true";
import { default as index2jsgzGaJlYMeta } from "/home/portal/www/frontend/pages/benefit/index.vue?macro=true";
import { default as indexOdJK4fWMNLMeta } from "/home/portal/www/frontend/pages/benefits/index.vue?macro=true";
import { default as indexBJCcOHmatsMeta } from "/home/portal/www/frontend/pages/benefitsOverview/index.vue?macro=true";
import { default as index9mG2cvgMomMeta } from "/home/portal/www/frontend/pages/checkout/validate/index.vue?macro=true";
import { default as _91id_93SPmiTTRsT0Meta } from "/home/portal/www/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93CD5NVNVaqRMeta } from "/home/portal/www/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexp2WMyyTt7JMeta } from "/home/portal/www/frontend/pages/index.vue?macro=true";
import { default as indexjBqWY6oiUGMeta } from "/home/portal/www/frontend/pages/magazine/index.vue?macro=true";
import { default as index8ICYuXJppCMeta } from "/home/portal/www/frontend/pages/magazineOverview/index.vue?macro=true";
import { default as _91id_93uEIWEy7jIKMeta } from "/home/portal/www/frontend/pages/media/[id].vue?macro=true";
import { default as indexfbyERHk8nqMeta } from "/home/portal/www/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexquTLuQCJIRMeta } from "/home/portal/www/frontend/pages/search/index.vue?macro=true";
import { default as indexognZldDqG1Meta } from "/home/portal/www/frontend/pages/start/index.vue?macro=true";
import { default as indexCWEZDTG6i3Meta } from "/home/portal/www/frontend/pages/user/[id]/index.vue?macro=true";
import { default as indexFlXOt9MvzXMeta } from "/home/portal/www/frontend/pages/user/login/index.vue?macro=true";
import { default as indexhQQJMkFO9dMeta } from "/home/portal/www/frontend/pages/user/register/index.vue?macro=true";
import { default as _91_91login_93_9370xJdroTKYMeta } from "/home/portal/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue?macro=true";
import { default as index0VeBKr1bNyMeta } from "/home/portal/www/frontend/pages/user/reset/index.vue?macro=true";
import { default as component_45stubF2RTGRnbIcMeta } from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubF2RTGRnbIc } from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexCanAjZThJGMeta || {},
    component: () => import("/home/portal/www/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "node-article",
    path: "/article",
    meta: index6qpNV45gGBMeta || {},
    component: () => import("/home/portal/www/frontend/pages/article/index.vue")
  },
  {
    name: "node-benefit",
    path: "/benefit",
    meta: index2jsgzGaJlYMeta || {},
    component: () => import("/home/portal/www/frontend/pages/benefit/index.vue")
  },
  {
    name: "taxonomy-benefits",
    path: "/benefits",
    meta: indexOdJK4fWMNLMeta || {},
    component: () => import("/home/portal/www/frontend/pages/benefits/index.vue")
  },
  {
    name: "benefits-overview",
    path: "/benefitsOverview",
    meta: indexBJCcOHmatsMeta || {},
    component: () => import("/home/portal/www/frontend/pages/benefitsOverview/index.vue")
  },
  {
    name: "checkout-validate",
    path: "/checkout/validate",
    component: () => import("/home/portal/www/frontend/pages/checkout/validate/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93SPmiTTRsT0Meta || {},
    component: () => import("/home/portal/www/frontend/pages/form/[id].vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93CD5NVNVaqRMeta || {},
    component: () => import("/home/portal/www/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexp2WMyyTt7JMeta || {},
    component: () => import("/home/portal/www/frontend/pages/index.vue")
  },
  {
    name: "taxonomy-categories",
    path: "/magazine",
    meta: indexjBqWY6oiUGMeta || {},
    component: () => import("/home/portal/www/frontend/pages/magazine/index.vue")
  },
  {
    name: "magazine-overview",
    path: "/magazineOverview",
    meta: index8ICYuXJppCMeta || {},
    component: () => import("/home/portal/www/frontend/pages/magazineOverview/index.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/home/portal/www/frontend/pages/media/[id].vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexfbyERHk8nqMeta || {},
    component: () => import("/home/portal/www/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexquTLuQCJIRMeta || {},
    component: () => import("/home/portal/www/frontend/pages/search/index.vue")
  },
  {
    name: "start",
    path: "/start",
    meta: indexognZldDqG1Meta || {},
    component: () => import("/home/portal/www/frontend/pages/start/index.vue")
  },
  {
    name: "user-overview",
    path: "/user/:id()",
    meta: indexCWEZDTG6i3Meta || {},
    component: () => import("/home/portal/www/frontend/pages/user/[id]/index.vue")
  },
  {
    name: "login",
    path: "/user/login",
    meta: indexFlXOt9MvzXMeta || {},
    component: () => import("/home/portal/www/frontend/pages/user/login/index.vue")
  },
  {
    name: "register",
    path: "/user/register",
    meta: indexhQQJMkFO9dMeta || {},
    component: () => import("/home/portal/www/frontend/pages/user/register/index.vue")
  },
  {
    name: "user-reset-id-timestamp-hash-login",
    path: "/user/reset/:id()/:timestamp()/:hash()/:login?",
    component: () => import("/home/portal/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue")
  },
  {
    name: "user-reset",
    path: "/user/reset",
    meta: index0VeBKr1bNyMeta || {},
    component: () => import("/home/portal/www/frontend/pages/user/reset/index.vue")
  },
  {
    name: component_45stubF2RTGRnbIcMeta?.name,
    path: "/account/register",
    component: component_45stubF2RTGRnbIc
  },
  {
    name: component_45stubF2RTGRnbIcMeta?.name,
    path: "/account/login",
    component: component_45stubF2RTGRnbIc
  },
  {
    name: component_45stubF2RTGRnbIcMeta?.name,
    path: "/account",
    component: component_45stubF2RTGRnbIc
  }
]